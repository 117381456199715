import api from "./api";

const route = "charges/";

export default {

    fetch() {

        return api().get(route);

    }

};