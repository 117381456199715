import { mdiLogoutVariant ,mdiHistory, mdiMagnify, mdiPlus , mdiViewDashboardOutline, mdiTune, mdiApps, mdiCog, mdiLifebuoy  } from "@mdi/js";


export default {
    search: mdiMagnify ,
    add: mdiPlus,
    dashboard: mdiViewDashboardOutline,
    settings: mdiTune,
    apps: mdiApps,
    params: mdiCog,
    support: mdiLifebuoy,
    time: mdiHistory,
    logout:mdiLogoutVariant

};