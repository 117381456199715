<template>
  <div>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_281_15671)">
        <path d="M16.2057 0.93811C8.07201 0.93811 1.47778 7.53234 1.47778 15.6657C1.47778 23.7997 8.07196 30.3936 16.2057 30.3936C24.3394 30.3936 30.9332 23.7997 30.9332 15.6657C30.9332 7.53234 24.3394 0.93811 16.2057 0.93811ZM21.7852 10.4848C20.0512 8.75096 17.663 7.96041 15.2296 8.33001C11.7938 8.85059 9.1187 11.7263 8.82954 15.1893C8.45402 19.6934 12.0072 23.4416 16.4037 23.4416C17.8024 23.4416 19.114 23.0638 20.2402 22.4031C20.2402 22.4031 20.2736 23.8997 20.1376 24.2298C18.9958 24.7396 17.7312 25.0232 16.4037 25.0232C11.1579 25.0232 6.90411 20.5927 7.24069 15.2487C7.5299 10.6597 11.2918 6.94868 15.8829 6.69966C18.526 6.55658 21.0246 7.50558 22.8833 9.36332L21.7852 10.4848ZM13.2803 18.9718C14.0257 19.7463 15.2077 19.9234 16.2881 19.9443L16.258 21.4293C14.7913 21.3838 13.2414 21.0646 12.214 19.9979C11.1889 18.9317 10.6405 17.5363 10.6679 16.0853C10.6974 14.6011 11.2977 13.227 12.3633 12.2015C13.4126 11.1918 14.8073 10.645 16.2926 10.6731L16.2469 12.1407C15.1669 12.1202 14.1649 12.5238 13.3904 13.2692C12.5996 14.0295 12.1572 15.0165 12.1363 16.0968C12.1156 17.1761 12.5346 18.1973 13.2803 18.9718Z" fill="black"/>
        <path d="M17.3574 16.3326H22.5656V22.6075L24.1021 21.4981V15.0947H17.3574V16.3326Z" fill="#25963A"/>
        <path d="M31.992 1.1241C31.992 1.74471 31.4888 2.24793 30.8685 2.24793C30.2476 2.24793 29.7451 1.74471 29.7451 1.1241C29.7451 0.503883 30.2476 0.000610352 30.8685 0.000610352C31.4888 0.000610352 31.992 0.503883 31.992 1.1241Z" fill="#25963A"/>
        <path d="M3.14176 30.4078C3.14176 31.2706 2.44174 31.9712 1.57841 31.9712C0.715093 31.9712 0.0157471 31.2706 0.0157471 30.4078C0.0157471 29.5445 0.715044 28.8439 1.57841 28.8439C2.44178 28.8439 3.14176 29.5445 3.14176 30.4078Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_281_15671">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>


</template>

<script>
export default {
  name: "GREEN_logo"
}
</script>

<style  lang="scss" scoped>
@import "../variables/variables.scss";

div{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 8px;
  border: 1px solid $color-primary;
}
</style>