<template>
  <div v-bind="$attrs" class="main-div-icon-link">
    <icon-link  :name="name"></icon-link>
    <slot name="content">
      <!-- content slot -->
    </slot>
  </div>

</template>

<script>
import IconLink from "../../assets/icons/IconLink";

export default {
  components: { IconLink },
  props: {
    name: {
      type: String
    }
  },
  name: "LeftNavigationLink"
}
</script>

<style lang="scss" scoped>
.main-div-icon-link{
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}
</style>