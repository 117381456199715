<template>
  <p class="ariane-filler"> <span class="ariane-greef">GREEF</span> > {{currentRouteName}} </p>
  <LeftNavigation />
  <router-view class="pages" />
</template>


<script>
import apiTest from '@/services/charges';
import LeftNavigation from "./components/navigations/LeftNavigation";


export default {
async mounted () {
 this.apiTesting = await apiTest.fetch()
.then(response => console.log(response))

.catch(e => console.log(e.response))

},
  name: 'App',
  components: {
    LeftNavigation,

  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  }
}
</script>

<style lang="scss">
@import url("./assets/fonts/fonts.scss");
@import "assets/variables/variables";

body{
  margin: 0;
  padding: 0;
}
#app {
  font-family: $font-primary;
  text-align: left;
}

.pages,
.ariane-filler{
  margin-left: 70px;
}
.ariane-greef{
  color: grey;
}
</style>
