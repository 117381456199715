<template>
<div>
  <slot name="first_content"></slot>
  <slot name="second_content"></slot>
</div>
</template>

<script>
export default {
  name: "DropDownMenu"
}
</script>

<style lang="scss" scoped>
@import "src/assets/variables/variables";

div{
  position: absolute;
  bottom: 0;
  z-index: 10;
  margin-left: 4.5rem;
  margin-bottom: 0.75rem;
  background-color: white;
  width: 20rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
}
</style>